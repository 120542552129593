<template lang="pug">
  v-app
    .d-flex.h-100(:class="mobileView ? 'flex-column' : 'flex-row'" style="justify-content: center")
      img.image(src="@/assets/images/contact/contact1.png")
      .image2
        a(href="whatsapp://send?phone=+601163460608&text=Hello%2C%20Libresse!")
          img(
            src="@/assets/images/contact/contact2.png"
          )
        a.link(href="mailto:libressetouch@vinda.com")
          img(
            src="@/assets/images/contact/contact3.png"
          )
        .social-container
          a(href="https://www.libresse.com.my/")
            img(
              src="@/assets/images/new/website.png"
            )
          a(href="https://www.instagram.com/libresse_my/")
            img(
              src="@/assets/images/new/instagram.png"
            )
          a(href="https://www.facebook.com/LibresseMalaysia/")
            img(
              src="@/assets/images/new/facebook.png"
            )
          a(href="https://www.youtube.com/user/livelibresse")
            img(
              src="@/assets/images/new/youtube.png"
            )
          a(href="https://www.tiktok.com/@libressemalaysia")
            img(
              src="@/assets/images/new/tiktok.png"
            )
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {}
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-up')}
  .v-application
    height: 82vh !important
    overflow: hidden !important

.image, .image2
  margin: auto 1rem
  width: 42%
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin: auto
    width: 90%

  .social-container
    padding: 1rem
    margin-top: 4rem
    display: flex

    a
      width: 64px
      margin: auto

      @media #{map-get($display-breakpoints, 'sm-and-down')}
        width: 48px
        margin-bottom: 4rem

.image2
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin-top: -20px
</style>
